/* ContactPage.module.css */

.contact_container {
  padding: 50px 0;
  min-height: 80vh;
  width: 100%;
  color: #ffffff;
  display: flex;
  align-items: center;
  border-radius: 22px;
}

.emailContainer {
  border-radius: 10px;
  border: 2px solid #ffffff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  padding: 20px;
  text-align: center;
}

.emailContainer h3 {
  font-weight: bold;
  margin-bottom: 10px;
}

.emailContainer p {
  margin: 0;
  font-size: 18px;
}

@media (min-width: 768px) {
  .emailContainer {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}
