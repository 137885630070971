/** @format */

.event_container {
  min-height: 80vh;
  width: 100%;
  color: #ffffff;
  display: flex;
  align-items: center;
  border-radius: 22px;
}

.event_container li button {
  padding: 5px 5px !important;
  border: none !important;
  outline: none !important;
  position: relative !important;
  z-index: 1 !important;
  border-radius: 4px;
  color: #ffffff;
  border: 1.5px solid rgba(255, 255, 255, 0.4) !important;
  background: rgba(255, 255, 255, 0.15) !important;
  cursor: pointer !important;
}

.timeline {
  position: relative;
  max-width: 1800px;
  color: #000000;
}
.timeline::after {
  content: "";
  position: absolute;
  width: 6px;
  top: 0;
  bottom: 0;
  left: 50%;
  margin-left: -3px;
}
.right {
  left: 50%;
}
.timeline__content {
  padding: 30px 0;
  position: relative;
  background-color: inherit;
  width: 50%;
  word-wrap: break-word;
}
.timeline__content::after {
  background-color: #fefefe;
}
.right::after {
  left: -14.2px;
}
.timeline__content::after {
  content: "";
  position: absolute;
  width: 25px;
  height: 25px;
  right: -10px;
  background-color: #d8d2d2;
  border: 4px solid #8179ff;
  top: 15px;
  border-radius: 50%;
  z-index: 1;
}

.timeline::after {
  content: "";
  position: absolute;
  width: 2px;
  background-color: #8179ff;
  border-radius: 8px;
  top: 0;
  bottom: 0;
  left: 50%;
  margin-left: -3px;
}
.left {
  left: 0;
}
.timeline__content {
  padding: 30px 0;
  position: relative;
  background-color: inherit;
  width: 50%;
  word-wrap: break-word;
}
.col {
  display: flex;
  flex-direction: column;
  text-align: left;
  width: 100%;
  justify-content: center;
}
.content {
  padding: 0 0px 0 30px;
  background-color: transparent;
  position: relative;
  border-radius: 6px;
}
.exp_header {
  color: #073eaa;
}
.exp__date {
  font-size: 14px;
  margin: 8px 0;
  color: #fd9b4d;
}
.exp__list li {
  font-size: 14px;
}
.exp__list li a {
  color: #073eaa;
}
@media screen and (max-width: 830px) {
  .timeline::after {
    left: 10px;
  }
  .timeline__content::before {
    left: 0;
    border: medium solid #fff;
    border-width: 10px 10px 10px 0;
    border-color: transparent #fff transparent transparent;
  }
  .left::after,
  .right::after {
    left: -4px !important;
  }
  .timeline__content {
    width: 100%;
    padding: 30px 0;
  }
  .right {
    left: 0;
  }
}
