/** @format */

.speaker_container {
  min-height: 80vh;
  width: 100%;
  color: #ffffff;
  display: flex;
  align-items: center;
  border-radius: 22px;
}
.speaker_container button {
  color: #ffffff !important;
  background: none !important;
  border-radius: 80px !important;
  border: 1px solid white !important;
  width: 300px !important;
  padding: 5px 5px !important;
  background: linear-gradient(
    99deg,
    rgba(255, 255, 255, 0.25) -65.03%,
    rgba(255, 255, 255, 0) 105.74%
  ) !important;
}

.speaker_container button::before {
  content: "";
  position: absolute;
  left: 1px;
  right: 1px;
  top: 1px;
  bottom: 1px;
  border-radius: 80px;
  background-color: #13121d;
  z-index: -1;
  transition: 200ms;
}

.speaker_container button::after {
  content: attr(data);
  font-size: 16px;
  background: linear-gradient(to right, #ffa80094, #8f00ff) !important;
  -webkit-background-clip: text;
  color: transparent;
  transition: 200ms;
}

.speaker_container ul li .button {
  border-radius: 20px !important;
}

@media screen and (max-width: 767.98px) {
  .speaker_container img {
    width: 60px !important;
    height: 60px !important;
  }
}
