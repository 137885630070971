/** @format */

.visaRequirement_container {
  min-height: 100vh;
  width: 100%;
  color: #ffffff;
  background: #13121d;
}

.nav_pills {
  padding: 23px 32px !important;
  overflow-x: auto;
}

.nav_pills li {
  padding: 10px 20px 10px 20px;
  border-radius: 26px 0px 0px 0px;
}

.nav_pills li button {
  width: 100% !important;
  color: #ffffff !important;
}

.nav_pills .nav_link.active {
  background-color: #453cdb !important;
  border-radius: 26px !important;
}
