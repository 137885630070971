/** @format */

.community_Container {
  min-height: 80vh;
  width: 100%;
  color: #ffffff;
  display: flex;
  align-items: center;
  border-radius: 22px;
  background: #13121d;
}

@media (max-width: 767.98px) {
  .community_Container {
    min-height: 60vh;
  }
}

@media (max-width: 575.98px) {
  .community_Container {
    min-height: 40vh;
    padding: 20px 0px;
  }
}
