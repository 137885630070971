/** @format */

.enlignment_Container {
  min-height: 80vh;
  width: 100%;
  color: #ffffff;
  display: flex;
  align-items: center;
  background: #100f1b;
  border-top-right-radius: 64px;
  border-top-left-radius: 64px;
}
