/** @format */

.feature_container {
  min-height: 80vh;
  width: 100%;
  color: #ffffff;
  display: flex;
  align-items: center;
}
.firstImage {
  text-align: center;
  width: 100%;
  height: 400px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
  background-repeat: no-repeat;
  background-image: url("../mystical-convocation.png");
}
.secondImage {
  text-align: center;
  width: 100%;
  height: 400px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
  background-repeat: no-repeat;
  background-image: url("../esoteric-presentation.png");
}
.thirdImage {
  text-align: center;
  width: 100%;
  height: 400px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
  background-repeat: no-repeat;
  background-image: url("../interactive-session.png");
}
.fourthImage {
  text-align: center;
  width: 100%;
  height: 400px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
  background-repeat: no-repeat;
  background-image: url("../panel-discussion.png");
}
.fifthImage {
  text-align: center;
  width: 100%;
  height: 400px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
  background-repeat: no-repeat;
  background-image: url("../cultural-dance.jpg");
}
.sixthImage {
  text-align: center;
  width: 100%;
  height: 400px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
  background-repeat: no-repeat;
  background-image: url("../banquet.png");
}

@media (max-width: 991.98px) {
  .firstImage {
    height: 384px;
  }
  .secondImage {
    height: 384px;
  }
  .thirdImage {
    height: 384px;
  }
  .fourthImage {
    height: 384px;
  }
  .fifthImage {
    height: 384px;
  }
  .sixthImage {
    height: 384px;
  }
}

@media (max-width: 767.98px) {
  .firstImage {
    height: 304px;
  }
  .secondImage {
    height: 304px;
  }
  .thirdImage {
    height: 304px;
  }
  .fourthImage {
    height: 304px;
  }
  .fifthImage {
    height: 304px;
  }
  .sixthImage {
    height: 304px;
  }
}

@media screen and (max-width: 575.98px) {
  .firstImage {
    height: 204px;
  }
  .secondImage {
    height: 204px;
  }
  .thirdImage {
    height: 204px;
  }
  .fourthImage {
    height: 204px;
  }
  .fifthImage {
    height: 204px;
  }
  .sixthImage {
    height: 204px;
  }
}
