/** @format */
.dignitaries_container {
  min-height: 100vh;
  width: 100%;
  color: #ffffff;
  display: flex;
  align-items: center;
  border-radius: 22px;
  background: #13121d;
}

.image_container {
  width: 300px;
  height: 300px;
}

.image_container img {
  height: 300px;
}
