/** @format */
.main_container {
  width: 100%;
  min-height: 100vh;
  background-image: url("../banner-img_an-image-of-people-in-a-convention-center.png");
  background-position: center;
  background-size: cover;
  background-repeat: repeat;
  color: #ffff;
}

@media screen and (max-width: 575.98px) {
  .main_container {
    min-height: 60vh;
  }

  .main_container_row {
    height: 60vh !important;
  }
}
