/** @format */

.app-btn-primary {
  background: #453cdb;
  color: #ffffff;
  width: 223px;
  height: 48px;
  padding: 0px 14px 0px 14px;
  border-radius: 32px !important;
}
.app-btn-primary:hover {
  background: #453cdb;
  color: #ffffff;
}

.app-btn-primary-outline {
  border: 1px solid #ffffff;
  color: #ffffff;
  width: 223px;
  height: 48px;
  padding: 0px 14px 0px 14px;
  border-radius: 32px !important;
}
.app-btn-primary-outline:hover {
  border: 1px solid #ffffff;
  color: #ffffff;
}

.dropdown-menu {
  margin-top: 10px !important;
}

.colorChange {
  width: 100%;
  position: fixed;
  top: 0px;
  z-index: 9999;
  box-shadow: 0 10px 30px rgb(0 0 0 / 10%);
  -webkit-transition: 0.5s;
  -moz-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
}

.navbar {
  background: transparent !important;
  position: absolute;
  width: 100%;
  z-index: 9999;
}

.colorChange .navbar {
  background: #13121d !important;
}

.colorChange a {
  color: #ffffff !important;
}

.header {
  max-width: 100%;
  margin-top: 0;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  background-color: transparent;
}

.navbar-toggler {
  color: #ffffff !important;
  border-color: #ffffff !important;
  border-radius: 2px;
}

.navbar-toggler .navbar-toggler-icon {
  background: #000000 !important;
}

.navbar-toggler .navbar-nav .nav-link {
  display: inline-block;
}

ul.nav {
  flex-wrap: nowrap !important;
}

.speaker-nav li button.active {
  padding: 5px 5px !important;
  border: none !important;
  outline: none !important;
  position: relative !important;
  z-index: 1 !important;
  border-radius: 80px !important;
  background: linear-gradient(to right, #ffa80094, #8f00ff) !important;
  cursor: pointer !important;
}

.table-responsive::-webkit-scrollbar-thumb {
  background-color: transparent;
}

.event li button.active {
  border-radius: 4px !important;
  border: 1.5px solid rgba(30, 41, 59, 0.5) !important;
  background: #4e43ff !important;
}

.list-group-item-action {
  border: none !important;
  background: none !important;
}

.visa .nav-link.active {
  background-color: #453cdb !important;
  border-radius: 26px !important;
}

.slick-dots li.slick-active button:before {
  color: #44e6e2 !important;
}

.slick-dots li button:before {
  color: #ffffff !important;
}
