/** @format */
.Gallery_container {
  min-height: 80vh;
  width: 100%;
  color: #ffffff;
  display: flex;
  align-items: center;
  border-radius: 22px;
  background: #13121d;
}

.Gallery_row {
  display: -ms-flexbox; /* IE10 */
  display: flex;
  -ms-flex-wrap: wrap; /* IE10 */
  flex-wrap: wrap;
  padding: 0 4px;
}

/* Create four equal columns that sits next to each other */
.Gallery_column {
  -ms-flex: 50%; /* IE10 */
  flex: 50%;
  max-width: 50%;
  padding: 0 4px;
}

.Gallery_column img {
  margin-top: 8px;
  vertical-align: middle;
  width: 100%;
}

/* Responsive layout - makes a two Gallery_column-layout instead of four Gallery_columns */
@media screen and (max-width: 800px) {
  .Gallery_column {
    -ms-flex: 50%;
    flex: 50%;
    max-width: 50%;
  }
}

/* Responsive layout - makes the two Gallery_columns stack on top of each other instead of next to each other */
@media screen and (max-width: 600px) {
  .Gallery_column {
    -ms-flex: 100%;
    flex: 100%;
    max-width: 100%;
  }
}
