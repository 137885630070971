/** @format */

@import url("https://fonts.googleapis.com/css2?family=Inter:wght@200;400;600&display=swap");
@import "slick-carousel/slick/slick.css";
@import "slick-carousel/slick/slick-theme.css";

body {
  margin: 0;
  font-family: "Inter", sans-serif;
  background: linear-gradient(
      90deg,
      rgba(57, 55, 102, 0) 22.27%,
      rgba(102, 99, 180, 0.52) 55%,
      rgba(102, 99, 180, 0.52) 60%,
      rgba(57, 55, 102, 0) 86.63%
    ),
    #13121d;
}

code {
  font-family: "Inter", sans-serif;
}

a {
  color: #ffffff;
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: 0em;
}

a:hover {
  color: #ffffff;
}

button {
  box-shadow: unset !important;
  border: none;
}

*::-webkit-scrollbar {
  width: 2px;
  height: 2px;
  background-color: rgba(12, 12, 12, 0);
}

*::-webkit-scrollbar-thumb {
  background-color: #00000073;
}

h1 {
  font-size: 76px;
  font-weight: 700;
  line-height: 92px;
  letter-spacing: 0em;
}

h2 {
  font-size: 32px;
  font-weight: 400;
  line-height: 39px;
  letter-spacing: 0em;
}

h3 {
  font-size: 28px;
  font-weight: 700;
  line-height: 35px;
  letter-spacing: 0em;
}

h4 {
  font-size: 24px;
  font-weight: 600;
  line-height: 35px;
  letter-spacing: 0em;
}

h6 {
  font-size: 18px;
  font-weight: 700;
  line-height: 21.6px;
  letter-spacing: 0em;
  font-family: "Inter", sans-serif;
}

span {
  font-size: 14px;
}

li {
  list-style: none !important;
  display: inline-block;
}
ul {
  margin: 0px !important;
  padding: 0px !important;
}
