/** @format */

.footer_container {
  min-height: 20vh;
  width: 100%;
  padding: 20px 0px;
  color: #ffffff;
  display: flex;
  align-items: center;
  border-radius: 22px;
  background: #13121d;
}
